import { useMemo } from 'react'
import useProducts from './useProducts'


const useProductsBy = (identifier: 'sku' | 'slugs', productsIdentifiers: string[], splitBySku = false) => {
  const { products = [], isFetching } = useProducts({
    variables: {
      input: {
        [identifier]: productsIdentifiers,
        visibility: [ 'LANDING', 'VISIBLE' ],
      },
    },
    skip: !productsIdentifiers.length,
    ssr: false,
  })

  const filteredProducts = useMemo(() => {
    if (!products?.length) {
      return []
    }

    const identifierMap = new Map(productsIdentifiers.map((id, index) => [ id, index ]))

    if (identifier === 'slugs') {
      return [ ...products ].sort((a, b) => {
        return identifierMap.get(a.id.toString()) - identifierMap.get(b.id.toString())
      })
    }

    let filteredProducts = products
      .map((product) => ({
        ...product,
        tradingItems: {
          ...product.tradingItems,
          items: product.tradingItems?.items?.filter((item) => identifierMap.has(item.sku)),
        },
      }))
      .filter((product) => Boolean(product.tradingItems.items.length))

    if (splitBySku) {
      // if we need to split by sku,
      filteredProducts = filteredProducts
        .map((product) => {
          // we collect all trading items to combine subscription and ecommerce SKU together and avoid their duplication
          const uniqueSkus = product.tradingItems.items.reduce((result, item) => {
            const existing = result.get(item.sku)
            if (existing) {
              existing.push(item)
            }
            else {
              result.set(item.sku, [ item ])
            }
            return result
          }, new Map<string, any[]>())

          // then we create unique item for each sku
          return Array.from(uniqueSkus.values()).map((items) => {
            return {
              ...product,
              tradingItems: {
                ...product.tradingItems,
                items,
              },
            }
          })
        })
        .flat()
        .filter((product) => Boolean(product.tradingItems.items.length))
    }

    return filteredProducts
      .sort((a, b) => {
        return identifierMap.get(a.tradingItems.items[0].sku) - identifierMap.get(b.tradingItems.items[0].sku)
      })

  }, [ products, productsIdentifiers, identifier, splitBySku ])

  return { isFetching, products: filteredProducts }
}


export default useProductsBy
